import React from 'react'
import {
    Player,
    ControlBar,
    PlayToggle
  } from 'video-react';
import './articles.css' 
import CPR from '../../media/cpr.png'
import videoCPR from '../../media/cpr-vid.mp4'
import HIVTestingVid from '../../media/HIV-testing-video.mov'

const Nigeria = () => {
    return (
        <div className="article">
            <h1>We set up free HIV testing sessions in Nigeria, thought children the importance of CPR and more!</h1>
            <br/><br/>
            <div className='left-pic-right-text'>
             <h2>Teaching CPR to kids</h2>
             <img alt="Impact od donations in nigeria" src={CPR} />
             <p>
               As part of our outreach, we taught the children the importance of CPR. 
               These steps are very simple, but with an incredible life-saving effect. 
             </p>
            </div>
            <div className='left-pic-right-text'>
             <h2>Our Medical Outreach</h2>
             <Player playsInline={true} className="article-video">
              <source src={videoCPR} />
              <ControlBar autoHide={false} className="my-class">
                <PlayToggle />
              </ControlBar>
             </Player>
             <p>
               We launched our medial outreach in Lagos in July 2022.
             </p>
            </div>
            <div className='left-pic-right-text'>
             <h2>HIV Testing in Lagos</h2>
             <Player playsInline={true} className="article-video">
              <source src={HIVTestingVid} />
              <ControlBar autoHide={false} className="my-class">
                <PlayToggle />
              </ControlBar>
             </Player>
             <p>Here are some long overdue photos from the HIV testing sessions at a local church in Lagos. 
                According to avert.org, gaps remain in reaching men and key populations which needs to be addressed. 
                Nevertheless, members from the local community attending this session was a success story!</p>
            <p>Along with mythbusting workshops, we want to provide communities with awareness and the 
                confidence of thriving instead of surviving, if living with the illness. For more information visit 
                <a href="http://aidsinfo.unaids.org" style={{color: "#ff0066"}}> http://aidsinfo.unaids.org</a></p><br/><br/>
            </div>
        </div>
    )
}

export default Nigeria
