import React from 'react';
import { InstagramEmbed } from 'react-social-media-embed';

const socialMediaInstagramFeedLinks = [
    {
        "url": "https://www.instagram.com/p/C0Cs6_bMRRw/",
        "isActive": true
    },
    {
        "url": "https://www.instagram.com/p/CwrxLdeMV07/",
        "isActive": false
    },
    {
        "url": "https://www.instagram.com/p/Cz6NGXGMhio/",
        "isActive": false
    },
    {
        "url": "https://www.instagram.com/p/CzHJB8FMK89/",
        "isActive": false
    },
    // {
    //     "url": "https://www.instagram.com/p/CyWQ06-o7rp/?fbclid=IwAR2IhYhyx02ati0v5GmnGnD9BM4xlfagHjMeCdGOGjysoP-GUK0RoadOQ0M&img_index=1",
    //     "isActive": false
    // },
    // {
    //     "url": "https://www.instagram.com/p/CwseeYoMfPB/",
    //     "isActive": false
    // },
]

const SocialMedia = () => {
  return (
    <div className="how-you-can-help-container">
        <h1 className="how-header">FOLLOW OUR WORK</h1>
        <div className="card-container">
            {socialMediaInstagramFeedLinks.map(data => {
                return (
                    <div className="component-container"
                      style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <InstagramEmbed
                        url={data.url}
                        width={328}
                      />
                   </div>
                )
            })}
        
        {/* <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
              {socialMediaInstagramFeedLinks.map((data) => {
                return (<div class={`carousel-item ${data.isActive && 'active'}`} key={data.url}>
                  <div className="component-container"
                    style={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <InstagramEmbed
                      url={data.url}
                      width={328}
                    />
                  </div>
                </div>)
              })}
            </div>

        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>

        </div> */}
            
        {/* <div className="component-div-container"
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <InstagramEmbed
            url="https://www.instagram.com/p/C0Cs6_bMRRw/"
            width={328}
          />
        </div> */}
        </div>    
    </div>
  )
}

export default SocialMedia