import React from 'react'

const Privacy = () => {
    return (
        <div style={{textAlign:"center"}}>
            <br/><br/>
            <h1>Privacy Policy</h1><br/>
            <p>We are committed to protecting your personal information and being transparent about what we do with it, no matter how you interact with us. That’s whether you want to work, volunteer or advocate for us, donate, or use our services, want information, training or want to learn more about what we do.
                    We are committed to using your personal information in accordance with our responsibilities. We are required to provide you with the information in this Privacy Notice under applicable law which includes:
                    the European Union General Data Protection Regulation (Regulation (EU) 2016/679) (‘GDPR’) and the Data Protection Act 2018 (‘DPA’) referred to as the ‘data protection legislation’
                    the Privacy and Electronic Communications Regulation (2003)</p><br/>
            <p>We won’t do anything with your information you wouldn’t reasonably expect.</p><br/>
            <p>Processing of your personal information is carried out by or on behalf of Fionita’s Project CIC, incorporated under the Companies Act 2006; registered as a Community Interest Company in England and Wales (12037325).
                    This notice, together with our website terms and conditions and our cookies policy tells you about how we collect, use and protect your personal information.
                    If you have any queries about our Privacy Notice, please get in touch with our Information Governance team:
                    Email: <a style={{color: "#FF0066"}} href = "mailto: admin@fionitasproject.org">admin@fionitasproject.org.</a></p><br/>
            <p>How and when we collect information about you</p><br/>
            <p>The personal information we collect from you is limited to what is necessary to enable us to carry out the purposes for which it is collected. The type of personal information we collect depends on the context of your interactions with Fionita’s Project and the choices you make, including your privacy settings.
                    The data we may collect, store and use can include the following:
                    Name and contact information. We may collect your first and last name, title, job title and company name, email address, postal address, phone number and other similar contact data.
                    Passwords, password hints and similar security information for authentication and account access.
                    Payment information. We collect data necessary to process your payment if you purchase one of our goods or services or make a donation, such as your payment instrument number (such as a credit card number or bank account number) and the security code associated with your payment instrument.
                    Whether you are a U.K. tax payer for claiming gift aid.
                    Any personal information which you choose to provide us with in correspondence with you.
                    Photographs, videography and CCTV footage.</p><br/>
            <p>Your I.P. address (or Internet Protocol Address). This is a unique address that computing devices such as personal computers, tablets, and smartphones use to identify itself. An I.P. address is analogous to a street address or telephone number and could therefore be used to identify you.
                    We may collect other online identifiers including cookies information (for more information please see section 14 (‘Cookies Policy’)), the internet browser and devices you are using and the pages you visited on our website and how long you visited us for.
                    You have choices about some of the personal information we collect. When you are asked to provide personal information, you may decline. Please note that if you choose not to provide personal information that is necessary to enable us to carry out your request- for instance, to make a donation, for information or to purchase one of our products or services- we may not be able to fulfil that request.
                    We may provide links via Fionitas’s Project ‘s websites to other websites or you might independently visit the website of a third party who provides services on our behalf, such as our lottery or event booking service. The privacy practices of these third-party websites are outside our control and in these cases, you should check the privacy notices of any third-party websites before disclosing any personal information.
                    In some cases, you might make a donation to us via a third-party payment processor, in which case you should check the privacy policy of that third party as the data controller of your personal information.</p><br/>
            <p>When you indirectly give us information</p><br/>
            <p>When you interact with us on social media platforms such as Facebook, Instagram, Twitter or LinkedIn we may also obtain some personal information about you. The information we receive will depend on the privacy preferences you have set on each platform and the privacy policies of each platform. To change your settings on these platforms, please refer to their privacy notices.
                    We may obtain information about your visit to our site, for example the pages you visit and how you navigate the site, by using cookies. Please visit our cookies policy for information on this.</p><br/>
            <p>What information we might collect</p><br/>
            <p>When you engage with us by phone, mail, in person or online, we may collect information about you (referred to in this Privacy Notice as 'personal information'). This may include your name, address, email address, telephone number, date of birth, job title and details of your education and career, why you are interested in British Red Cross, and other information relating to you personally which you may choose to provide to us.
                    Data protection law recognises that certain types of personal information are more sensitive. This is known as 'sensitive' or 'special category' personal information and covers information revealing racial or ethnic origin, religious or philosophical beliefs and political opinions, trade union membership, genetic or biometric data, information concerning health or data concerning a person's sex life or sexual orientation.
                    Sensitive information will only be collected where necessary, for example, we may need to collect health information from you when you register for a challenge event or to deliver a community service as a volunteer or member of staff. Clear notices will be provided at the time we collect this information, stating what information is needed, and why.
                    With your explicit consent, we may also collect sensitive personal information if you choose to tell us about your experiences relating to our services for use in a case study.</p><br/>
            <p>If you're under 16</p><br/>
            <p>If you're aged under 16, you must get your parent/guardian’s permission before you provide any personal information to us.</p><br/>
            <p>How and why we use your information</p><br/>
            <p>We will use your personal information for the following purposes:
                    Donation processing: We will process personal information you provide in order to administer any one-off or on-going donations you make and claim Gift Aid.
                    Responding to a request: If you contact us with a query, we may use your personal information to provide you with a response.
                    Fundraising or direct marketing: We will only send you marketing information by email, if you have given us specific consent. If you withdraw your consent and then subsequently opt in to receive marketing information again, then your most recent preference may supersede. If you respond to a fundraising appeal by post, you may also receive fundraising mail, which you can opt out of at any time.
                    Email: <a style={{color: "#FF0066"}} href = "mailto: admin@fionitasproject.org">admin@fionitasproject.org.</a></p><br/>
            <p> Monitoring and Evaluating: We may use your information in order to improve current and future delivery of our services.
                    Co-production: We may invite you to participate in projects or initiatives that enable you to help develop or review our services, or shape our research, media, policy and advocacy activity. Participation is always voluntary. Your decision whether to participate will not affect you accessing a Fionita’s Project service. No individuals will be identified as participating in co-production projects, unless they explicitly consent to this.
                    Processing an application to work with us: If you apply to work with us, we will only use the information you give us to process your application or to monitor recruitment statistics on an unidentified basis. If we want to disclose information to someone outside the Fionita’s Project, for example, if we need a reference from your previous employer, we will tell you beforehand. The only exception is where the law obliges us to disclose information to a third party (such as the police) and we are not allowed to tell you.
                    If you are unsuccessful in your job application, we may hold your personal information after we've finished recruiting for the post you applied for, for up to 12 months to deal with any follow up queries or issues.
                    We keep statistical information about all applicants to develop our recruitment processes however no individual applicant would be identifiable from this information.
                    If you commence employment with the British Red Cross, your personal information will be processed in accordance with your employment contract and other applicable human resources policies we have from time to time.
                    Transactional purposes: We will need to use your personal information in order to carry out our obligations arising from any contracts entered into between you and us for goods or services, for example, processing your order and payment for a product from our online shop.
                    Providing and developing our website: We may use your personal information to help provide you with access to our website, personalise your experience, and improve and develop it further.
                    Administration: We may use your personal information to record and deal with a complaint, record a request not to receive further marking information, record what our volunteers have done for us, and for other essential internal record keeping purposes.
                    Prevention of crime: We may record your image on CCTV which we use to prevent crime and keep our people and the public safe.
                    Protecting your vital interests: We may process your personal information where we reasonably think that there is a risk of serious harm or abuse to you or someone else.
                    Market research and surveys: We may invite you to participate in surveys or market research to help us improve our website, fundraising, services and strategic development. Participation is always voluntary and no individuals will be identified as a result of this research, unless you consent to us publishing your feedback.
                    Legal, regulatory and tax compliance: Where required we are subject to a legal obligation, we may process your personal information to fulfil that obligation.
                    Profiling and analysis: We may occasionally for the purposes of our legitimate interests use your personal information to conduct profiling of our supporters or potential supporters. This will help us target communications in a more focused, efficient and cost effective way, helping us reduce the chances of supporters and potential supporters receiving inappropriate or irrelevant communications. You can object to such use of your personal information for profiling at any time by contacting us at the details set out at the end of this Privacy Notice.
                    Our profiling and analysis activities can be broken into five categories:<br/>
                    <ol>
                    <br/><li>1. Data matching</li>
                        <br/>
                        We may combine the personal information you have given us with data obtained from external sources, such as the Office for National Statistics, 
                        to infer the likely social, demographic and financial characteristics, so we can tailor our communications and services to better meet your needs 
                        or the needs of others like you based on the insight we gain from the profile we build. We will not use the results of this data matching activity 
                        in a way that intrudes on your privacy or your previously expressed privacy preferences.
                        <br/><br/><li>2. Segmenting</li>
                        <br/>
                        We conduct analysis of supporters by group, post code or particular area where supporters may be based. This is to ensure that campaigns or mailings 
                        are sent to those who will be most interested or likely to respond. This type of activity is not aimed at identifying specific individuals to target, 
                        but rather many individuals who may fall within a certain segment of supporters.
                        <br/><br/><li>3. Major donor analysis</li>
                        <br/>
                        We may carry out research to determine whether an individual could be a potential major donor. We may use publicly available information from third 
                        party sources such as Google; Companies House; Mint UK; Wealth-X; published biographies and publicly available LinkedIn profiles. The type of 
                        information we collect can include:
                          <ul>
                            <li>career overview</li>
                            <li> gift capacity</li>
                            <li>areas of interest </li>
                            <li>history of giving to us and others</li>
                            <li>how the individual is connected with us and others</li>
                            <li>public information on any philanthropic activities</li>
                          </ul>
                          <br/><li>4. High value event planning</li>
                        <br/>
                        We may also use profiling to produce short biographies of people who are due to meet with our leadership or attend an event that we may be hosting.
                        This helps our people to understand more about those we engage with, and their interests or connection to us.
                        <br/><li>5. Ethical screening and minimising risk</li>
                        <br/>
                        As a registered Community Interest Company, we are subject to a number of legal and regulatory obligations and standards. The public naturally expect charities to operate in an ethical manner and this is integral to developing high levels of trust and demonstrating our integrity.
                    This means that we may carry out appropriate due diligence of donors, check donations and implement robust financial controls to help protect the charity from abuse, fraud and/or money laundering.
                    We may carry out background checks and due diligence on potential donors or anyone planning on making a significant donation or gift before we can accept it.
                    We may also ethically screen supporters to minimise risk of creating an association with an individual or group that conflicts with the standards we have set out in our overarching ethical policy.
                    </ol>
                    </p><br/>
            <p> Who do we share your information with?</p><br/>
            <p>We will only use your information for the purposes for which it was obtained. We will not, under any circumstances, sell or share your personal information with any third party for their own purposes, and you will not receive marketing from any other companies, charities or other organisations as a result of giving your details to us.
                    We will only share your data for the following purposes:<br/>
                    Third party suppliers: We may need to share your information with data hosting providers or service providers who help us to deliver our services, projects, or fundraising activities and appeals. These providers will only act under our instruction and are subject to pre-contract scrutiny and contractual obligations containing strict data protection clauses.
                    Where legally required: We will comply with requests where disclosure is required by law, for example, we may disclose your personal information to the government for tax investigation purposes, or to law enforcement agencies for the prevention and detection of crime. We may also share your information with the emergency services if we reasonably think there is a risk of serious harm or abuse to you or someone else.
                    We always aim to ensure that personal information is only used by those third parties for lawful purposes in accordance with this Privacy Notice.</p><br/>
            <p>How we protect your information</p><br/>
            <p>We use technical and corporate organisational safeguards to ensure that your personal information is secure. We limit access to information on a need-to-know basis and take appropriate measures to ensure that our people are aware that such information is only used in accordance with this Privacy Notice.
                    We undertake regular reviews of who has access to information that we hold to ensure that your information is only accessible by appropriately trained staff, volunteers and contractors.
                    Our online forms are always encrypted and our network is protected and routinely monitored.
                    If you use your credit or debit card to donate to us, buy something or make a booking online, we pass your card details securely to our payment processing partners. We do this in accordance with industry standards and do not store the details on our website.
                    However, please be aware that there are always inherent risks in sending information by public networks or using public computers and we cannot 100% guarantee the security of data (including personal information) disclosed or transmitted over public networks.</p><br/>
            <p>Vulnerable circumstances</p><br/>
            <p> We understand that additional care may be needed when we collect and process the personal information of vulnerable supporters and volunteers. In recognition of this, we observe good practice guideline in our interactions with vulnerable people.</p><br/>
            <p>How long will we keep your information?</p><br/>
            <p> We will keep your personal information in respect of financial transactions for as long as the law requires us to for tax or accounting purposes (which may be up to six years after a particular transaction).
                    If you request that we stop processing your personal information for the purpose of marketing we may in some instances need to add your details to a suppression file to enable us to comply with your request not to be contacted.
                    In respect of other personal information, we will retain it for no longer than necessary for the purposes for which it was collected, taking into account guidance issued by the Information Commissioner’s Office.
                    International transfers of information</p><br/>
            <p>International transfers of information</p><br/>
            <p>We may, on occasion decide to use the services of a supplier outside the European Economic Area (EEA), which means that your personal information is transferred, processed and stored outside the EEA. You should be aware that, in general, legal protection for personal information in countries outside the EEA may not be equivalent to the level of protection provided in the EEA.
                    However we take steps to put in place suitable safeguards to protect your personal information when processed by the supplier such as entering into the European Commission approved standard contractual clauses. When we transfer your personal information and process it in the United States, we do so in accordance with the EU-U.S. Privacy Shield Framework and the European Commission approved standard contractual clauses. By submitting your personal information to us you agree to this transfer, storing or processing at a location outside the EEA.
                    Your rights to your personal information</p><br/>
            <p>Your rights to your personal information</p><br/>
            <p>Data protection legislation gives you the right to request access to personal information about you which is processed by the British Red Cross and to have any inaccuracies corrected.
                    You also have the right to ask us to erase your personal information, ask us to restrict our processing of your personal information or to object to our processing of your personal information.
                    If you wish to exercise these rights, please complete this request form (PDF) and send it along with copies of two separate identification documents which provide photo identification and confirm your address, such as a passport, driving licence, or utility bill.
                    Please also provide any additional information that is relevant to the nature of your contact with us, as this will help us to locate your records.
                    You can send us the documents via email:
                    </p>
            <p> along with scans or photos of your two forms of identification to: <a style={{color: "#FF0066"}} href = "mailto: admin@fionitasproject.org">admin@fionitasproject.org.</a>
                    We will respond within 30 days, on receipt of your written request and copies of your identification documents.
                    </p><br/><br/>
            <p>Changes to our Privacy Notice</p><br/>
            <p>Our Privacy Notice may change from time to time, so please check this page occasionally to see if we have included any updates or changes, and that you are happy with them.
                    (Last updated: 8th July 2021)</p><br/>
        </div>
    )
}

export default Privacy
